<template>
    <span>
        <articles-widget v-if="activeArticlesWidget"/>
        <learning-widget v-if="activeLearningWidget && !current_user.is_admin && module.testing"/>
    </span>
</template>

<script>
    import { mapState } from 'vuex';

    export default {
        name: 'WrapperMaterials',
        components: {
            ArticlesWidget: () => import('../widgets/Articles'),
            LearningWidget: () => import('../widgets/Learning')
        },
        props: {
            activeArticlesWidget: {
                type: Boolean,
                required: false,
                default: false
            },
            activeLearningWidget: {
                type: Boolean,
                required: false,
                default: false
            }
        },
        computed: {
            ...mapState('wrapper_materials', [
                'favourites', 'old'
            ]),
            ...mapState('default_data', [
                'current_user', 'module'
            ])
        },
        created() {
            this.$store.dispatch('wrapper_materials/initialize');
            this.$store.dispatch('wrapper_materials/initRecommendMaterials');
        }
    };
</script>

<style scoped>

</style>
